import React from "react"
import {graphql} from "gatsby"
import Slider from "@pavelcorp/infinite-react-slider";
// Layout
import Layout from "../components/layout"
// React Helmet SEO
import SEO from "../components/seo"


import style from "../modules/index.module.scss"


function IndexPage({data}) {
  const { allWebsitesYaml, allToolsYaml, allPackagesYaml} = data;

  const allPackages = allPackagesYaml.edges.map(({node}) =>(
    <a 
      href={node.url} 
      target="_blank"
      className={`White ${style.Project}`}
    >
      {node.name}

      <p>
        {node.description}
      </p>
    </a>
  ))

  const allTools = allToolsYaml.edges.map(({ node }) => (
    <a 
      key={node.id}
      href={node.url}
      className={`${style.Tool}`}
      target={`_blank`}
    >
      <div 
        style={
        {
          width: `${node.width}px`,
        }
      }
      >
        <span 
          style={
            {
              paddingBottom: `${100 * node.height / node.width}%`,
            }
          } 
          aria-hidden={true} 
        />
        <img
          width={node.width}
          height={node.height}
          src={node.image.publicURL}
          alt={node.name}
        />
      </div>
    </a>
  ));

  return (
    <Layout>
      <SEO title={`Home`} />

      <div className={style.Page}>
        <header className={`${style.Header} mb6 mbmd10`}>
          <div className={`wrapper`}>
            <h1 className={`t01 Pink`}>
              Pavelcorp
            </h1>
            <p>Building modern websites</p>
          </div>
        </header>
        
        <div className={`wrapper`}>

          <div className={`grid`}>
            <div className={`grid__item md8 lg8`}>
              <h2 className={`Pink t02 mb3 LinedTitle`}>
                <span>
                  Websites
                </span>
              </h2>
            </div>
          </div>

          <div className={`grid mb6 mbmd10`}>
            {
              allWebsitesYaml.edges.map(({node}) => (
                <div 
                  key={node.id}
                  className={`grid__item sm6 md4 lg4`}
                >
                  <a 
                    href={node.url}
                    target={`_blank`}
                    className={`${style.Website} mb3`}
                  >
                    <img 
                      src={node.image.publicURL} 
                      alt={node.name} 
                    />
                  </a>
                </div>
              ))
            }
          </div>

          <div className={`grid`}>
            <div className={`grid__item md8 lg8`}>
              <h2 className={`Pink t02 mb3 LinedTitle`}>
                <span>
                  Packages & Projects
                </span>
              </h2>
            </div>
          </div>

          <div className={`mb6 mbmd10`}>
            {allPackages}
          </div>

          <div className={`grid`}>
            <div className={`grid__item md8 lg8`}>
              <h2 className={`Pink t02 mb3 LinedTitle`}>
                <span>
                  Tools
                </span>
              </h2>
            </div>
          </div>

          <div className={`mb6 mbmd10`}>
            <Slider>
              {allTools}
            </Slider>
          </div>

          <div className={`grid`}>
            <div className={`grid__item md8 lg8`}>
              <h2 className={`Pink t02 mb3 LinedTitle`}>
                <span>
                  Contact
                </span>
              </h2>
            </div>
          </div>
          <p>
            <a 
              href="mailto:pavel@pavelcorp.com"
              className={`White`}
            >
              pavel@pavelcorp.com
            </a>
            {` • `}
            <a 
              href="mailto:david@callmelord.com"
              className={`White`}
            >
              david@callmelord.com
            </a>
          </p>

        </div>

      </div>


    </Layout>
  )
}

export default IndexPage

export const query = graphql`
{
  allPackagesYaml{
    edges {
      node {
        id
        name
        url
        description
      }
    }
  }
  allToolsYaml {
    edges {
      node {
        id
        image {
          publicURL
        }
        width
        height
        name
        url
      }
    }
  }
  allWebsitesYaml {
    edges {
      node {
        id
        name
        url
        image {
          publicURL
        }
      }
    }
  }
}
`